import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Menu, MenuItem } from '@material-ui/core';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';
import routeEndpoints from 'route/endpoints';

import styles from '../dashboard.module.css';

import { ReactComponent as ChevronDown } from 'asset/IconsPack/at_chevron-down-filled.svg';
import { ReactComponent as ChevronUp } from 'asset/IconsPack/at_chevron-up.svg';
import { ReactComponent as LogoutIcon } from 'asset/IconsPack/at_log-out-03.svg';
import { ReactComponent as TicketIcon } from 'asset/IconsPack/at_ticket-01.svg';
import { ReactComponent as HelpIcon } from 'asset/icons/chat_icon.svg';

import { userSettingsStyles } from './userSettingsStyles';

const MenuIcon = ({ menuOpen }) => {
    return menuOpen ? (
        <ChevronUp className={styles.dropdownIcon} />
    ) : (
        <ChevronDown className={styles.dropdownIcon} />
    );
};

function UserSettings({ firstName, handleHelpClick }) {
    const classes = userSettingsStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const openSettingsMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        history.push(routeEndpoints.logout);
    };

    const handleChatSupport = () => {
        setAnchorEl(null);
        handleHelpClick();
    };

    return (
        <>
            <Button
                intent="secondary"
                size="lg"
                className={styles.userNameDropdown}
                endIcon={<MenuIcon menuOpen={openSettingsMenu} />}
                onClick={handleClick}
                id="user-setting-menu"
                aria-controls={openSettingsMenu ? 'user-setting-menu' : undefined}
                aria-expanded={openSettingsMenu ? 'true' : undefined}
            >
                {firstName}
            </Button>
            <Menu
                id="user-setting-menu"
                className={classes.usersDropDownMenu}
                anchorEl={anchorEl}
                open={openSettingsMenu}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                disableEnforceFocus
            >
                <MenuItem className={classes.userMenuItem} onClick={handleChatSupport}>
                    <HelpIcon className={styles.commonIconStyle} /> Chat Support
                </MenuItem>
                <MenuItem
                    component="a"
                    href="https://info.authenticate.com/kb-tickets/new"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.userMenuItem}
                    onClick={() => setAnchorEl(null)}
                >
                    <TicketIcon className={styles.commonIconStyle} /> Raise a Support Ticket
                </MenuItem>
                <MenuItem className={classes.userMenuItem} onClick={handleLogout}>
                    <LogoutIcon className={styles.commonIconStyle} /> Logout
                </MenuItem>
            </Menu>
        </>
    );
}

UserSettings.propTypes = {
    firstName: PropTypes.string,
    handleHelpClick: PropTypes.func,
};

MenuIcon.propTypes = {
    menuOpen: PropTypes.bool,
};

export default UserSettings;
