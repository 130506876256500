import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Typography, Button } from '@material-ui/core';

import actionConstants from 'redux/actions/actionConstant';
import { openToast } from 'redux/actions/toaster';
import { useGetActivationStatusQuery } from 'redux/slices/globalApi';

import classes from './incompleteWarning.module.css';

import { ReactComponent as AlertIconOutlined } from 'asset/icons/alert_outlined.svg';

const IncompleteWarning = () => {
    const dispatch = useDispatch();
    const { integrationName } = useParams();
    const [isActivationComplete, setIsActivationComplete] = useState(true);

    const { data: activationStatusData, refetch } = useGetActivationStatusQuery({
        integrationName,
    });

    useEffect(() => {
        if (activationStatusData?.isComplete === false) {
            setIsActivationComplete(false);
        }
    }, [activationStatusData]);

    const handleConfirm = useCallback(async () => {
        try {
            dispatch({ type: actionConstants.START_LOADER });

            const result = await refetch({ integrationName }).unwrap();
            if (result?.isComplete === true) {
                setIsActivationComplete(true);
            } else {
                setIsActivationComplete(false);
                dispatch(
                    openToast({
                        variant: 'error',
                        message:
                            result?.errorMessage ||
                            'Hostaway custom field is either missing or has not been created correctly. Please verify and try again.',
                    })
                );
            }
        } catch (err) {
            setIsActivationComplete(false);
        } finally {
            dispatch({ type: actionConstants.STOP_LOADER });
        }
    }, [integrationName, refetch, dispatch]);

    return (
        <>
            {!isActivationComplete ? (
                <Box className={classes?.warningBanner}>
                    <AlertIconOutlined className={classes?.warningIcon} />
                    <Box className={classes?.warningContent}>
                        <Box>
                            <Typography className={classes?.warningHeading}>
                                Final Step to activate your Hostaway Integration.
                            </Typography>
                        </Box>
                        <Box className={classes?.warningInfo}>
                            <Typography className={classes?.warningInfo}>
                                You're almost done connecting your Hostaway and Authenticate
                                accounts. There is one final, short step left. which is to manually
                                add a reservation drop-down custom field to your account. Please
                                follow the steps in the{' '}
                                <a
                                    href="https://info.authenticate.com/hostaway-integration"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.warningLinkText}
                                >
                                    link
                                </a>{' '}
                                , and click on the Confirm button after you are done.
                            </Typography>
                            <Box>
                                <Button
                                    variant="text"
                                    className={classes?.warningConfirmBtn}
                                    onClick={handleConfirm}
                                >
                                    Confirm
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <></>
            )}{' '}
        </>
    );
};

export default IncompleteWarning;
