import { useDispatch } from 'react-redux';

import { openToast } from 'redux/actions/toaster';

const useIntegrationToaster = () => {
    const dispatch = useDispatch();

    const showSuccessToast = (message) => {
        dispatch(
            openToast({
                variant: 'success',
                message,
            })
        );
    };

    const createErrorToaster = (message) => {
        dispatch(
            openToast({
                variant: 'error',
                message,
            })
        );
    };

    const showErrorToast = (err, defaultErrorMessage, classes) => {
        if (err?.data?.errorMessage) {
            const errMsg =
                'The integration token provided is invalid, please get a new token from the Guesty Marketplace.';

            const contentWithGuestyLink = (
                <>
                    The integration token provided is invalid, please get a new token from the{' '}
                    <a
                        href="https://app.guesty.com/integrations/partners/authenticate.com"
                        target="_blank"
                        rel="noreferrer"
                        className={classes?.guestyMarketLink}
                    >
                        Guesty Marketplace
                    </a>
                    {''}.
                </>
            );

            const customMessage =
                err?.status === 400 && err?.data?.errorMessage === errMsg
                    ? contentWithGuestyLink
                    : err?.data?.errorMessage;

            createErrorToaster(customMessage);
        } else {
            createErrorToaster(defaultErrorMessage);
        }
    };

    return {
        showSuccessToast,
        showErrorToast,
    };
};

export default useIntegrationToaster;
