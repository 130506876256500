import { useState, useRef, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyGetListingsQuery } from 'redux/slices/globalApi';

export const useListings = (type, brandUuid) => {
    const [triggerGetListingsApi, { data, isLoading, isFetching }] = useLazyGetListingsQuery();
    const { integrationName } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTags, setSearchTags] = useState([]);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);

    const listingRef = useRef();

    const handleScroll = () => {
        const clientHeight = listingRef?.current?.clientHeight;
        const scrollTop = listingRef?.current?.scrollTop;
        const scrollHeight = listingRef?.current?.scrollHeight;
        const reachedEnd = clientHeight + scrollTop >= scrollHeight - 5;

        if (reachedEnd && !isFetching && data?.listings?.length > 0) {
            setPage((prev) => prev + 1);
        }
    };

    const onSubmit = useCallback(
        async (searchTerms) => {
            const prepareSearchTerms = searchTerms.map((item) => item.label);

            if (prepareSearchTerms.length === 0) {
                setRows([]);
                setPage(1);
            } else {
                try {
                    if (brandUuid !== 'new') {
                        const query = {
                            page: page,
                            payload: {
                                searchTerms: prepareSearchTerms,
                                brandUuid,
                                ignoreCurrentBrand: type === 'move',
                            },
                            integrationName,
                        };
                        const result = await triggerGetListingsApi(query).unwrap();

                        const newRows = result?.listings?.map((item) => ({
                            id: item.uuid,
                            ...(type === 'move' && { currentBrand: item.brandName }),
                            name: item.name,
                            address: item.address,
                        }));
                        setRows((prevRows) => (page === 1 ? newRows : [...prevRows, ...newRows]));
                    }
                } catch (err) {}
            }
        },
        [brandUuid, page, type, integrationName, triggerGetListingsApi]
    );

    useEffect(() => {
        (async () => await onSubmit(searchTags))();
    }, [page, onSubmit, searchTags]);

    useEffect(() => {
        if (searchTags.length === 0) {
            setRows([]);
            setPage(1);
        }
    }, [searchTags]);

    const onRemoveSearchTag = async (id) => {
        const updatedTags = searchTags.filter((tag) => tag.id !== id);
        setSearchTags(updatedTags);
        setPage(1);
        await onSubmit(updatedTags);
    };

    return {
        listingRef,
        handleScroll,
        isLoading,
        isFetching,
        rows,
        setRows,
        searchTerm,
        setSearchTerm,
        searchTags,
        setSearchTags,
        onSubmit,
        onRemoveSearchTag,
        totalCount: data?.total,
        currentBrandListings: data?.currentBrandListings,
        page,
    };
};
