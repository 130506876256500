import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Typography, IconButton } from '@material-ui/core';
import Tooltip from 'components/Tooltip';
import Button from 'components/V2/Button';
import Chip from 'components/V2/Chip';
import PropTypes from 'prop-types';
import OverflowingTooltipText from 'views/V2/Integration/components/OverflowingTooltipText';
import SelectPassCriteria from 'views/V2/Integration/components/SelectPassCriteria';
import SelectWorkflow from 'views/V2/Integration/components/SelectWorkflow';
import useFetchWorkflow from 'views/V2/Integration/hooks/useFetchWorkflow';

import {
    useMarkDefaultMutation,
    globalAPI,
    useLazyFetchOneWorkflowQuery,
} from 'redux/slices/globalApi';

import styles from './editBrandForm.module.css';

import { ReactComponent as RefreshIcon } from 'asset/IconsPack/at_refresh-cw-01-filled.svg';
import { ReactComponent as EditIcon } from 'asset/IconsPack/hx_pencil-02.svg';

import PassCriteriaPreview from '../PassCriteriaPreview';
import WorkflowPreview from '../WorkflowPreview';

function EditBrandForm({
    brandData,
    formik,
    listings,
    openBrandNameModal,
    setIsNewBrand,
    canUserUpdate,
    setUnsavedChanges,
}) {
    const dispatch = useDispatch();
    const { integrationName } = useParams();
    const [makeDefaultBrand] = useMarkDefaultMutation();

    const { data: allWorkflow } = useFetchWorkflow();
    const [fetchWorkflowApi, { data: currentWorkflow }] = useLazyFetchOneWorkflowQuery();

    const makeBrandDefault = async () => {
        try {
            await makeDefaultBrand({ brandUUID: brandData?.uuid, integrationName }).unwrap();
        } catch (err) {}
    };

    const isDefaultBrand = brandData?.isDefault;
    const isListingsAvailable = listings?.length === 0;

    const handleRefreshWorkflows = () => {
        dispatch(globalAPI.util.invalidateTags(['updateBrand', 'workflows', 'workflow']));
    };

    const onOpenEditModal = () => {
        openBrandNameModal(true);
        setIsNewBrand(false);
    };

    const fetchOneWorkflow = useCallback(
        async (id) => {
            try {
                await fetchWorkflowApi(id);
            } catch (err) {}
        },
        [fetchWorkflowApi]
    );

    useEffect(() => {
        if (formik.values.preferredWorkflowId) {
            (async () => await fetchOneWorkflow(formik.values.preferredWorkflowId))();
        }
    }, [formik.values.preferredWorkflowId, fetchOneWorkflow]);

    return (
        <Box className={styles.editBrandFromContainer}>
            {canUserUpdate && (
                <>
                    {isDefaultBrand ? (
                        <Chip content="Default" size="lg" className={styles.defaultBrandTag} />
                    ) : (
                        <Tooltip
                            title={
                                isListingsAvailable
                                    ? 'You need to have at least one listing under this brand to make this default.'
                                    : ''
                            }
                            arrow
                            placement="bottom-start"
                        >
                            <span>
                                <Chip
                                    content="Make Default"
                                    size="lg"
                                    className={styles.makeDefaultBrandTag}
                                    onClick={isListingsAvailable ? undefined : makeBrandDefault}
                                />
                            </span>
                        </Tooltip>
                    )}
                </>
            )}
            <Box className={styles.brandHeader}>
                <OverflowingTooltipText
                    title={brandData?.brandName}
                    className={styles.brandHeading}
                />
                {canUserUpdate && (
                    <IconButton className={styles.editBrandName} onClick={onOpenEditModal}>
                        <EditIcon className={styles.editIcon} />
                    </IconButton>
                )}
            </Box>
            <Box className={styles.brandOptionsContainer}>
                <Box className={styles.brandOptionsHeader}>
                    <Typography className={styles.editOptionHeading}>
                        {canUserUpdate ? 'Select Workflow' : 'Workflow'}
                    </Typography>
                    {canUserUpdate && (
                        <Button
                            intent="secondary"
                            size="md"
                            onClick={handleRefreshWorkflows}
                            startIcon={<RefreshIcon className={styles.refreshIcon} />}
                            className={styles.refreshButton}
                        >
                            Refresh
                        </Button>
                    )}
                </Box>
                <Box>
                    {canUserUpdate && (
                        <SelectWorkflow
                            formik={formik}
                            allWorkflow={allWorkflow}
                            setUnsavedChanges={setUnsavedChanges}
                        />
                    )}
                    <WorkflowPreview workflowData={currentWorkflow} />
                </Box>
            </Box>
            <Box className={styles.brandOptionsContainer}>
                <Box className={styles.brandOptionsHeader}>
                    <Typography className={styles.editOptionHeading}>Pass Criteria</Typography>
                </Box>
                <Box>
                    {canUserUpdate && (
                        <SelectPassCriteria
                            formik={formik}
                            workflow={currentWorkflow}
                            setUnsavedChanges={setUnsavedChanges}
                        />
                    )}
                    <PassCriteriaPreview data={formik.values?.passCriteria ?? []} />
                </Box>
            </Box>
        </Box>
    );
}

EditBrandForm.propTypes = {
    brandData: PropTypes.object,
    formik: PropTypes.object,
    listings: PropTypes.array,
    openBrandNameModal: PropTypes.func,
    setIsNewBrand: PropTypes.func,
    canUserUpdate: PropTypes.bool,
    setUnsavedChanges: PropTypes.func,
};

export default EditBrandForm;
