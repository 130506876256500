import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Dashboard from 'layouts/V2/Dashboard';

import actionConstants from 'redux/actions/actionConstant';
import { selectCompanyAccessCode } from 'redux/selectors/auth';
import { selectGuestyActiveState, selectIntegrations } from 'redux/selectors/integrations';
import {
    useUpdateIntegrationSettings,
    useGetDefaultBrandQuery,
    useGetIntegrationSettingsQuery,
} from 'redux/slices/globalApi';
import { setActiveIntegrations } from 'redux/slices/integration';

import classes from './guesty.module.css';

import IntegrationHeader from '../../components/IntegrationHeader';
import { GUESTY_SETTING_NAMES, redirectingUrls, toasterMessage } from '../../constants';
import useIntegrationToaster from '../../hooks/useIntegrationToaster';
import ActivateGuesty from './components/ActivateGuesty';
import ActivateModal from './components/ActivateModal';
import DeactivateModal from './components/DeactivateModal';
import HomePageLayout from './components/HomePageLayout';
import ViewListings from './components/ViewListings';

const GuestyPage = () => {
    const dispatch = useDispatch();
    const { integrationName } = useParams();
    const { showSuccessToast, showErrorToast } = useIntegrationToaster();

    const [updateIntegrationSettings, { isLoading: isProcessing }] = useUpdateIntegrationSettings();

    const [openActivateModal, setOpenActivateModal] = useState(false);
    const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [startSetup, setStartSetup] = useState(false);

    const companyAccessCode = useSelector(selectCompanyAccessCode);
    const activeIntegrations = useSelector(selectIntegrations);

    const isActive = Boolean(useSelector(selectGuestyActiveState) ?? 0);

    const { data } = useGetDefaultBrandQuery(
        { integrationName },
        {
            skip: !isActive,
            refetchOnMountOrArgChange: true,
        }
    );

    const [deactivating, setDeactivating] = useState(false);

    const settingId = activeIntegrations?.find((key) => key.name === 'Guesty').id;

    const { data: integrationSettings } = useGetIntegrationSettingsQuery(settingId);

    const defaultBrandUuid = data?.uuid;

    const onCloseActivateModal = () => {
        setOpenActivateModal(false);
    };

    const onCloseDeactivateModal = () => {
        setOpenDeactivateModal(false);
    };

    const onClickActivateNow = () => {
        setOpenActivateModal(true);
    };

    const mapIntegrationSettings = (values, isActivating = true) => {
        return integrationSettings
            .map((setting) => {
                const { integration_id, id: integration_setting_id, name } = setting;
                let newSetting = {
                    integration_id,
                    integration_setting_id,
                    name,
                };

                switch (name) {
                    case GUESTY_SETTING_NAMES.ACTIVE:
                        newSetting.value = isActivating ? 1 : 0;
                        break;
                    case GUESTY_SETTING_NAMES.API:
                        newSetting.value = isActivating ? values.token : null;
                        break;
                    case GUESTY_SETTING_NAMES.PASS_CRITERIA:
                    case GUESTY_SETTING_NAMES.ENABLED_ADDITIONAL_CHECKS:
                        newSetting = undefined;
                        break;
                    default:
                        newSetting.value = setting.value ? setting.value : '0';
                }
                return newSetting;
            })
            .filter(Boolean);
    };

    const onActivateIntegration = async (values, resetForm) => {
        const newSettings = mapIntegrationSettings(values, true);

        const payload = {
            companyAccessCode,
            settings: newSettings,
            key: {
                id: settingId,
            },
        };

        setOpenActivateModal(false);
        try {
            await updateIntegrationSettings(payload).unwrap();
            showSuccessToast(toasterMessage.TOKEN_ACTIVATION_SUCCESS);

            const guestyIndex = activeIntegrations?.findIndex((item) => item.name === 'Guesty');
            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[guestyIndex] = {
                ...integrationsCopy[guestyIndex],
                active: 1,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
        } catch (err) {
            showErrorToast(err, toasterMessage.TOKEN_ACTIVATION_FAILED, classes);
        }

        resetForm();
    };

    const onClickSetupNow = () => {
        setStartSetup(true);
    };

    const onClickDeactivate = () => {
        setOpenDeactivateModal(true);
    };

    const onDeactivateIntegration = async () => {
        const newSettings = mapIntegrationSettings({}, false);

        const payload = {
            companyAccessCode,
            settings: newSettings,
            key: {
                id: settingId,
            },
        };

        setOpenDeactivateModal(false);
        setDeactivating(true);
        try {
            dispatch({ type: actionConstants.START_LOADER });

            await updateIntegrationSettings(payload).unwrap();

            showSuccessToast(toasterMessage.TOKEN_DEACTIVATION_SUCCESS);
            setStartSetup(false);
            const guestyIndex = activeIntegrations?.findIndex((item) => item.name === 'Guesty');

            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[guestyIndex] = {
                ...integrationsCopy[guestyIndex],
                active: 0,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
        } catch (err) {
            showErrorToast(err, toasterMessage.TOKEN_DEACTIVATION_FAILED);
        } finally {
            dispatch({ type: actionConstants.STOP_LOADER });
            setDeactivating(false);
        }
    };

    const onClickViewAll = () => {
        setOpenViewModal(true);
    };

    return (
        <Dashboard mainContainerStyles={classes.guestyActivePageWrapper}>
            <>
                <IntegrationHeader
                    title="Guesty Integrations"
                    link="https://info.authenticate.com/guesty-integration"
                />

                {isActive || startSetup ? (
                    <HomePageLayout
                        isProcessing={isProcessing && !deactivating}
                        onClickDeactivate={onClickDeactivate}
                        onClickViewAll={onClickViewAll}
                        brandUuid={defaultBrandUuid}
                    />
                ) : (
                    <ActivateGuesty
                        isProcessing={isProcessing}
                        onClickSetupNow={onClickSetupNow}
                        onClickActivateNow={onClickActivateNow}
                        integrationLink={redirectingUrls.GUESTY_AUTHENTICATE_MARKETPLACE}
                    />
                )}

                <ActivateModal
                    open={openActivateModal}
                    setOpen={setOpenActivateModal}
                    onClose={onCloseActivateModal}
                    onSubmit={onActivateIntegration}
                />
                <DeactivateModal
                    open={openDeactivateModal}
                    setOpen={setOpenDeactivateModal}
                    onClose={onCloseDeactivateModal}
                    onConfirm={onDeactivateIntegration}
                />
                <ViewListings
                    brandUuid={defaultBrandUuid}
                    open={openViewModal}
                    onClose={() => setOpenViewModal(false)}
                />
            </>
        </Dashboard>
    );
};

export default GuestyPage;
