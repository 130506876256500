export const selectGuestyActiveState = (state) =>
    state?.integrationV2?.activeIntegrations?.find((integration) => integration.name === 'Guesty')
        ?.active;

export const selectHostawayActiveState = (state) =>
    state?.integrationV2?.activeIntegrations?.find((integration) => integration.name === 'Hostaway')
        ?.active;

export const selectIntegrations = (state) => state?.integrationV2?.activeIntegrations;

export const selectLodgifyActiveState = (state) =>
    state?.integrationV2?.activeIntegrations?.find((integration) => integration.name === 'Lodgify')
        ?.active;
