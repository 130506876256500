import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Grid, Divider } from '@material-ui/core';
import Button from 'components/V2/Button';
import useAccessRole from 'hooks/useAccessRole';
import PropTypes from 'prop-types';
import ActivateMenu from 'views/V2/Integration/components/ActivateMenu';
import Listings from 'views/V2/Integration/components/Listings';
import useBrandListings from 'views/V2/Integration/hooks/useBrandListings';

import styles from './homePageLayout.module.css';

// assets
import { ReactComponent as BuildingIcon } from 'asset/IconsPack/at_building-07.svg';

import BrandPreview from '../BrandPreview';
import Processing from '../Processing';

function HomePageLayout({ isActive, onClickDeactivate, isProcessing, onClickViewAll, brandUuid }) {
    const { integrationName } = useParams();

    const { listingRef, listings, onChange, handleScroll, searchTerm, totalCount, isFetching } =
        useBrandListings(brandUuid, integrationName);

    const history = useHistory();
    const configureBrandRedirect = () => {
        history.push(`${history?.location?.pathname}/brands/${brandUuid}`);
    };

    const { canUserUpdate } = useAccessRole();

    return (
        <Box className={styles.layoutContainer}>
            <Grid className={styles.innerGridLayout}>
                <Box className={styles.layoutHeader}>
                    {isProcessing && !isActive ? (
                        <Processing />
                    ) : (
                        <ActivateMenu
                            onClickDeactivate={onClickDeactivate}
                            canUserUpdate={canUserUpdate}
                        />
                    )}
                    <Button
                        intent="primary"
                        startIcon={<BuildingIcon className={styles.buildingIcon} />}
                        disabled={isProcessing}
                        onClick={configureBrandRedirect}
                        className={styles.configureBtn}
                    >
                        {canUserUpdate ? 'Configure Brands' : 'View Brands'}
                    </Button>
                </Box>
                <Divider className={styles.sectionDivider} />
                <Box className={styles.layoutBody}>
                    <Box>
                        <BrandPreview isProcessing={isProcessing} canUserUpdate={canUserUpdate} />
                    </Box>
                    <Divider
                        className={styles.sectionDividerVertical}
                        orientation="vertical"
                        flexItem
                    />
                    <Box className={styles.listingsContainer}>
                        <Listings
                            ref={listingRef}
                            onScroll={handleScroll}
                            value={searchTerm}
                            onChange={onChange}
                            listings={listings}
                            total={totalCount ?? 0}
                            isLoading={isFetching}
                            btnProps={{
                                text: 'View All',
                                onClick: onClickViewAll,
                            }}
                        />
                    </Box>
                </Box>
            </Grid>
        </Box>
    );
}

HomePageLayout.propTypes = {
    isProcessing: PropTypes.bool,
    isActive: PropTypes.bool,
    onClickDeactivate: PropTypes.func,
    onClickViewAll: PropTypes.func,
    brandUuid: PropTypes.string,
};

export default HomePageLayout;
