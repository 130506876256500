import { useEffect } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';

const HubSpotTrackerScript = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    useEffect(() => {
        if (isMobile) return;

        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = 'hs-script-loader';
        script.src = '//js.hs-scripts.com/6431620.js';

        document.body.appendChild(script);

        return () => document.body.removeChild(script);
    }, [isMobile]);
    return null;
};

export default HubSpotTrackerScript;
