import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Loader from 'components/Loader';
import IntegrationPage from 'views/V2/Integration';
import ReNewPassword from 'views/V2/LoginFlow/ReNewPassword';
import DefaultWorkflow from 'views/Workflows/DefaultWorkflow';

import LoginRoute from './LoginRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { RedirectRoute } from './RedirectRoute';
import endPoints from './endpoints';

//Layout
const Dashboard = lazy(() => import('layouts/V2/Dashboard'));

//screen
const LoginOld = lazy(() => import('views/Login'));
const Login = lazy(() => import('views/V2/LoginFlow/Login/index'));

const TwoFAOld = lazy(() => import('views/2FA'));
const TwoFA = lazy(() => import('views/V2/LoginFlow/TwoFA'));

const ResetPasswordOld = lazy(() => import('views/ResetPassword'));
const ResetPassword = lazy(() => import('views/V2/LoginFlow/ResetPassword'));
const SetResetPassword = lazy(() => import('views/SetResetPassword'));

const VerifyEmail = lazy(() => import('views/VerifyEmail'));
const Signup = lazy(() => import('views/V2/SignUp'));
const Home = lazy(() => import('views/Home'));
const BillingDashboard = lazy(() => import('views/BillingDashboard'));
const Workflows = lazy(() => import('views/Workflows'));
const NotFound = lazy(() => import('views/NotFound'));
const Settings = lazy(() => import('views/Settings'));
const TermsAndPrivacy = lazy(() => import('views/TermsAndPrivacy'));
const EmbeddedDocument = lazy(() => import('views/Home/SignDocument/EmbeddedDocument'));
const TCM = lazy(() => import('views/TCM'));
const Users = lazy(() => import('views/Users'));
const Logout = lazy(() => import('views/Logout'));

const Routes = () => {
    const location = useLocation();

    useEffect(() => {
        if (window?._hsq) {
            window._hsq.push(['setPath', location.pathname]);
            window._hsq.push(['trackPageView']);
        }
    }, [location]);
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <LoginRoute
                    path={endPoints.default}
                    exact
                    ComponentNew={Login}
                    ComponentOld={LoginOld}
                />
                <LoginRoute
                    path={endPoints.twoFA}
                    exact
                    ComponentNew={TwoFA}
                    ComponentOld={TwoFAOld}
                />

                <LoginRoute
                    path={endPoints.resetPassword}
                    exact
                    ComponentNew={ResetPassword}
                    ComponentOld={ResetPasswordOld}
                />

                <LoginRoute
                    path={`${endPoints.setResetPassword}/:token`}
                    exact
                    ComponentNew={ReNewPassword}
                    ComponentOld={SetResetPassword}
                    type="forgot-password"
                />

                <LoginRoute
                    path={`${endPoints.setInvitePassword}/:token`}
                    exact
                    ComponentNew={ReNewPassword}
                    ComponentOld={SetResetPassword}
                    type="invite-password"
                />

                <PublicRoute
                    path={`${endPoints.verifyEmail}`}
                    component={() => <VerifyEmail />}
                    exact
                />

                <PublicRoute path={endPoints.register} component={Signup} exact />

                <PublicRoute path={endPoints.consent} component={() => <TermsAndPrivacy />} exact />

                {/* Redirecting to the new user profile link */}
                <PrivateRoute
                    path={`${endPoints.dashboard}/user/:uuid`}
                    component={({ match }) => <Redirect to={`/users/${match.params.uuid}`} />}
                    exact
                />

                <PrivateRoute
                    path={`${endPoints.users}/user/:uuid`}
                    component={({ match }) => <Redirect to={`/users/${match.params.uuid}`} />}
                    exact
                />

                <PrivateRoute
                    path={endPoints.userSearch}
                    component={() => <Redirect to={endPoints.users} />}
                    exact
                />

                <PrivateRoute
                    path={endPoints.dashboard}
                    component={() => (
                        <Dashboard>
                            <Home />
                        </Dashboard>
                    )}
                />

                <PrivateRoute
                    path={endPoints.users}
                    component={() => (
                        <Dashboard>
                            <Users />
                        </Dashboard>
                    )}
                />

                <PrivateRoute
                    path={endPoints.billingDashboard}
                    component={() => (
                        <Dashboard>
                            <BillingDashboard />
                        </Dashboard>
                    )}
                    exact
                />
                <PrivateRoute path={endPoints.workflows} component={() => <Workflows />} />
                <PrivateRoute path={endPoints.integration} component={IntegrationPage} />
                <PrivateRoute
                    path={endPoints.settings}
                    component={() => (
                        <Dashboard>
                            <Settings />
                        </Dashboard>
                    )}
                    exact
                />
                <PrivateRoute path={endPoints.verifyUi} component={DefaultWorkflow} exact />

                <PrivateRoute
                    path={endPoints.tcm}
                    component={() => (
                        <Dashboard>
                            <TCM />
                        </Dashboard>
                    )}
                />

                <PrivateRoute
                    path={endPoints.signDocument}
                    component={() => <EmbeddedDocument />}
                    exact
                />

                <PrivateRoute path={endPoints.logout} component={() => <Logout />} />

                <Route
                    path="/index.html"
                    component={() => <RedirectRoute pathname="/" includeQueryParams={true} />}
                />
                <Route path="*" component={NotFound} />
            </Switch>
        </Suspense>
    );
};
export default Routes;
