import React from 'react';

import { Box } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Modal from 'components/ModalV2';
import Input from 'components/V2/Input';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

//note: we can move it to a common file later
import classes from '../../../Guesty/components/ActivateModal/activateModal.module.css';

import { lodgifyActivateFormValidationSchema as validationSchema } from '../../../../utils';

const ActivateModal = ({ open, setOpen, onClose, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            apiKey: '',
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            await onSubmit(values, resetForm);
        },
        enableReinitialize: true,
    });

    const handleClose = () => {
        onClose();
        formik.resetForm();
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} disableBackdropClick={true}>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
                <FormHeader title="Enter API Key" handleClose={handleClose} />
                <Box className={classes.inputWrapper}>
                    <Input
                        value={formik.values.token}
                        variant="outlined"
                        label="API Key *"
                        labelClass={classes.inputLabel}
                        type="text"
                        name="apiKey"
                        className={classes.inputField}
                        handleChange={formik.handleChange}
                        error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
                        helperText={formik.touched.apiKey && formik.errors.apiKey}
                    />
                </Box>
                <FormFooter secondaryBtnHandler={handleClose} btnText="Submit" />
            </form>
        </Modal>
    );
};

ActivateModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default ActivateModal;
