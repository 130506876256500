import React from 'react';

import { Modal, Box } from '@material-ui/core';
import cn from 'clsx';
import PropTypes from 'prop-types';

import styles from './modal.module.css';

function BasicModal({
    open,
    onClose,
    children,
    className,
    /* some forms open as modal in mobile view it required to be shown in fullscreen, this optional prop make is fullscreen if required */
    mobileFullScreen,
    disableBackdropClick = false,
}) {
    const onBackdropClick = (e) => {
        if (!disableBackdropClick) {
            onClose(e);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={styles.modalContainer}
            BackdropProps={{
                onClick: onBackdropClick,
            }}
            disableEnforceFocus
        >
            <Box
                className={cn(
                    styles.modalInnerContainer,
                    mobileFullScreen && styles.modalMobileFullScreen,
                    className
                )}
            >
                {children}
            </Box>
        </Modal>
    );
}

BasicModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    mobileFullScreen: PropTypes.bool,
    disableBackdropClick: PropTypes.bool,
};

export default BasicModal;
