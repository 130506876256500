import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import ListingsModal from 'views/V2/Integration/components/ListingsModal';
import SearchBrandAndListings from 'views/V2/Integration/components/SearchBrandAndListings';
import useListingSelection from 'views/V2/Integration/hooks/useListingSelection';
import { useListings } from 'views/V2/Integration/hooks/useListings';
import { prepareListingsTableHeader } from 'views/V2/Integration/utils';

import actionConstants from 'redux/actions/actionConstant';
import { openToast } from 'redux/actions/toaster';
import { useMoveListingsMutation } from 'redux/slices/globalApi';

import classes from './moveListings.module.css';

import { ReactComponent as RightArrowIcon } from 'asset/IconsPack/at_arrow-right-filled.svg';

import ConfirmMoveListings from '../ConfirmMoveListings';
import ListingsReusableContent from '../ListingsReusableContent';

const MoveListings = ({ open, onClose, onCancel, brandUuid, brandName }) => {
    const [isMoveDisabled, setIsMoveDisabled] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const { integrationName } = useParams();
    const {
        currentBrandListings,
        totalCount,
        listingRef,
        handleScroll,
        isLoading,
        isFetching,
        rows,
        searchTerm,
        setSearchTerm,
        searchTags,
        setSearchTags,
        onSubmit,
        onRemoveSearchTag,
    } = useListings('move', brandUuid);

    const {
        selectedItems: selectedListings = [],
        setSelectedItems,
        setIsChecked,
        setIsSelectAll,
        onChange,
        ...selectionProps
    } = useListingSelection(rows, setIsMoveDisabled);

    const [moveListings] = useMoveListingsMutation();

    const dispatch = useDispatch();
    const header = prepareListingsTableHeader(['Current Brand', 'Listing Name', 'Address']);

    useEffect(() => {
        if (searchTags.length === 0) {
            setSelectedItems([]);
            setIsSelectAll(false);
        }
    }, [searchTags, setSelectedItems, setIsSelectAll]);

    const onMove = () => {
        if (selectedListings.length > 100) {
            dispatch(
                openToast({
                    variant: 'error',
                    message: 'You are not allowed to select more than 500 listings',
                })
            );
        } else {
            setOpenModal(true);
        }
    };

    const onConfirm = async () => {
        try {
            const data = {
                integrationName,
                payload: {
                    ...(!selectionProps?.isSelectAll && {
                        listings:
                            selectedListings.length <= 500
                                ? [...selectedListings.map((item) => item.id)]
                                : undefined,
                    }),
                    all: selectionProps.isSelectAll,
                    filters: {
                        searchTerms: searchTags.map((item) => item.label),
                        brandUuid,
                        ignoreCurrentBrand: selectionProps.isSelectAll,
                    },
                    destinationBrandUuid: brandUuid,
                },
            };

            dispatch({
                type: actionConstants.START_LOADER,
            });
            const result = await moveListings(data);

            if (result?.data?.success) {
                const count = selectionProps?.isSelectAll ? totalCount : selectedListings.length;
                dispatch(
                    openToast({
                        variant: 'success',
                        message: `${count} ${
                            count > 1 ? 'Listings have been' : 'Listing has been'
                        } moved successfully.`,
                    })
                );
            }
        } catch (err) {
        } finally {
            dispatch({
                type: actionConstants.STOP_LOADER,
            });
            setOpenModal(false);
            onClose();
        }
    };

    const handleScrollExtended = () => {
        handleScroll();
    };

    const onCloseExtended = () => {
        setSearchTags([]);
        onClose();
    };

    return (
        <>
            <ListingsModal open={open} onClose={onCloseExtended} className={classes.container}>
                <ListingsModal.Header title="Move Listings" onClose={onCloseExtended}>
                    <SearchBrandAndListings
                        value={searchTerm}
                        setValue={setSearchTerm}
                        onSubmit={onSubmit}
                        tags={searchTags}
                        setTags={setSearchTags}
                        removeSearchTag={onRemoveSearchTag}
                    />
                </ListingsModal.Header>
                <ListingsModal.Content>
                    <ListingsReusableContent
                        addSelect
                        ref={listingRef}
                        handleScroll={handleScrollExtended}
                        header={header}
                        rows={rows}
                        selectedListings={selectedListings}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        totalListingsCount={totalCount}
                        currentBrandListings={currentBrandListings}
                        setIsMoveDisabled={setIsMoveDisabled}
                        onChange={onChange}
                        scrollWrapperClass={classes.scrollWrapper}
                        {...selectionProps}
                    />
                </ListingsModal.Content>
                <ListingsModal.Footer
                    infoText="You are attempting to move some listings from this brand"
                    primaryBtnProps={{
                        title: 'Move',
                        onClick: onMove,
                        endIcon: <RightArrowIcon className={classes.rightArrowIcon} />,
                        disabled: isMoveDisabled,
                    }}
                    secondaryBtnProps={{
                        title: 'Cancel',
                        onClick: onCloseExtended,
                    }}
                />
            </ListingsModal>
            <ConfirmMoveListings
                data={{ header, rows: selectedListings, brandName }}
                open={openModal}
                onDeleteAction={selectionProps.handleToggleItemSelection}
                onClose={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                onConfirm={onConfirm}
            />
        </>
    );
};

MoveListings.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    brandUuid: PropTypes.string,
    brandName: PropTypes.string,
};

export default MoveListings;
