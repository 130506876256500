import { createApi } from '@reduxjs/toolkit/query/react';
import apiEndpoints from 'api/endpoints';

import { baseAuthQuery } from './baseApi';

export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: baseAuthQuery,
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.login}`,
                method: 'POST',
                body: payload,
            }),
        }),
        verifyOtp: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.login2FA}`,
                method: 'POST',
                body: payload,
            }),
        }),
        resendOtp: builder.query({
            query: () => ({
                url: `${apiEndpoints.twoFAResend}`,
                method: 'GET',
            }),
        }),
        resetPassword: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.reset}`,
                method: 'POST',
                body: payload,
            }),
        }),
        setNewPassword: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.setForgetPassword}`,
                method: 'POST',
                body: payload,
            }),
        }),
        setNewPasswordInvite: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.setInvitePassword}`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const {
    useLoginMutation: useLogin,
    useVerifyOtpMutation: useVerifyOtp,
    useLazyResendOtpQuery: useResendOtp,
    useResetPasswordMutation: useResetPassword,
    useSetNewPasswordMutation: useSetNewPassword,
    useSetNewPasswordInviteMutation: useSetNewPasswordInvite,
} = loginApi;

export default loginApi.middleware;
