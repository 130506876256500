import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Grid, Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';
import Chip from 'components/V2/Chip';
import PropTypes from 'prop-types';
import OverflowingTooltipText from 'views/V2/Integration/components/OverflowingTooltipText';
import { getGuestyPassCriteria } from 'views/V2/Integration/constants';

import { useGetDefaultBrandQuery } from 'redux/slices/globalApi';

import styles from './brandPreview.module.css';

import { ReactComponent as EditIcon } from 'asset/IconsPack/hx_pencil-02.svg';

import PassCriteriaPreview from '../PassCriteriaPreview';
import WorkflowPreview from '../WorkflowPreview';
import WorkflowThemePreview from '../WorkflowThemePreview';

function BrandPreview({ isProcessing, canUserUpdate }) {
    const history = useHistory();
    const { integrationName } = useParams();

    const { data } = useGetDefaultBrandQuery({ integrationName });

    const defaultBrandId = data?.uuid;

    const handleEditBrandRedirect = async () => {
        history.push(`${history?.location?.pathname}/brands/${defaultBrandId}/edit`);
    };

    const passCriteria = getGuestyPassCriteria(data?.preferredWorkflowConfig)
        ?.filter((criteria) => !criteria.disabled)
        .map((item) => item.value);

    return (
        <Grid className={styles.previewContainer}>
            <Box className={styles.brandHeader}>
                <Box className={styles.headingBox}>
                    <Chip content="Default" size="lg" className={styles.defaultTag} />
                    <Button
                        intent="secondary"
                        startIcon={<EditIcon className={styles.editIcon} />}
                        onClick={handleEditBrandRedirect}
                        disabled={isProcessing || !canUserUpdate}
                    >
                        Edit Brand
                    </Button>
                </Box>
                <OverflowingTooltipText title={data?.brandName} className={styles.brandHeading} />
            </Box>
            <Box className={styles.previewSection}>
                <Typography className={styles.previewSectionHeading}>Pass Criteria</Typography>
                <PassCriteriaPreview data={passCriteria ?? []} />
            </Box>
            <Box className={styles.workflowSection}>
                <Box className={styles.previewSection}>
                    <Typography className={styles.previewSectionHeading}>Workflow</Typography>
                    <WorkflowPreview
                        className={styles.workflowPreviewBox}
                        workflowData={data?.preferredWorkflowConfig}
                    />
                </Box>
                <Box className={styles.previewSection}>
                    <Typography className={styles.previewSectionHeading}>Theme</Typography>
                    <WorkflowThemePreview
                        previewScreens={2}
                        workflowData={data?.preferredWorkflowConfig}
                    />
                </Box>
            </Box>
        </Grid>
    );
}

BrandPreview.propTypes = {
    isProcessing: PropTypes.bool,
    canUserUpdate: PropTypes.bool,
};

export default BrandPreview;
