import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Dashboard from 'layouts/V2/Dashboard';

import actionConstants from 'redux/actions/actionConstant';
import { selectCompanyAccessCode } from 'redux/selectors/auth';
import { selectIntegrations, selectLodgifyActiveState } from 'redux/selectors/integrations';
import {
    useUpdateIntegrationSettings,
    useGetDefaultBrandQuery,
    useGetIntegrationSettingsQuery,
} from 'redux/slices/globalApi';
import { setActiveIntegrations } from 'redux/slices/integration';

import classes from '../Guesty/guesty.module.css';

import IntegrationHeader from '../../components/IntegrationHeader';
import { LODGIFY_SETTING_NAMES, redirectingUrls, toasterMessage } from '../../constants';
import useIntegrationToaster from '../../hooks/useIntegrationToaster';
import ActivateGuesty from '../Guesty/components/ActivateGuesty';
import DeactivateModal from '../Guesty/components/DeactivateModal';
import HomePageLayout from '../Guesty/components/HomePageLayout';
import ViewListings from '../Guesty/components/ViewListings';
import ActivateModal from './components/ActivateModal';

const LodgifyPage = () => {
    const dispatch = useDispatch();
    const { integrationName } = useParams();
    const { showSuccessToast, showErrorToast } = useIntegrationToaster();

    const [updateIntegrationSettings, { isLoading: isProcessing }] = useUpdateIntegrationSettings();

    const [openActivateModal, setOpenActivateModal] = useState(false);
    const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [startSetup, setStartSetup] = useState(false);

    const companyAccessCode = useSelector(selectCompanyAccessCode);
    const activeIntegrations = useSelector(selectIntegrations);

    const isActive = Boolean(useSelector(selectLodgifyActiveState) ?? 0);

    const { data } = useGetDefaultBrandQuery(
        { integrationName },
        {
            skip: !isActive,
            refetchOnMountOrArgChange: true,
        }
    );

    const [deactivating, setDeactivating] = useState(false);

    const settingId = activeIntegrations?.find((key) => key.name === 'Lodgify').id;

    const { data: integrationSettings } = useGetIntegrationSettingsQuery(settingId);

    const defaultBrandUuid = data?.uuid;

    const onCloseActivateModal = () => {
        setOpenActivateModal(false);
    };

    const onCloseDeactivateModal = () => {
        setOpenDeactivateModal(false);
    };

    const onClickActivateNow = () => {
        setOpenActivateModal(true);
    };

    const mapIntegrationSettings = (values, isActivating = true) => {
        const activeSetting = integrationSettings.find(
            (setting) => setting.name === LODGIFY_SETTING_NAMES.ACTIVE
        );
        const apiKeySetting = integrationSettings.find(
            (setting) => setting.name === LODGIFY_SETTING_NAMES.API_KEY
        );

        return [
            {
                integration_id: activeSetting.integration_id,
                integration_setting_id: activeSetting.id,
                value: isActivating ? 1 : 0,
            },
            {
                integration_id: apiKeySetting.integration_id,
                integration_setting_id: apiKeySetting.id,
                value: isActivating ? values.apiKey : null,
            },
        ];
    };

    const onActivateIntegration = async (values, resetForm) => {
        const newSettings = mapIntegrationSettings(values, true);

        const payload = {
            companyAccessCode,
            settings: newSettings,
            key: {
                id: settingId,
            },
        };

        setOpenActivateModal(false);
        try {
            await updateIntegrationSettings(payload).unwrap();
            showSuccessToast(toasterMessage.TOKEN_ACTIVATION_SUCCESS);

            const lodgifyIndex = activeIntegrations?.findIndex((item) => item.name === 'Lodgify');
            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[lodgifyIndex] = {
                ...integrationsCopy[lodgifyIndex],
                active: 1,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
        } catch (err) {
            showErrorToast(err, toasterMessage.TOKEN_ACTIVATION_FAILED, classes);
        }

        resetForm();
    };

    const onClickSetupNow = () => {
        setStartSetup(true);
    };

    const onClickDeactivate = () => {
        setOpenDeactivateModal(true);
    };

    const onDeactivateIntegration = async () => {
        const newSettings = mapIntegrationSettings({}, false);

        const payload = {
            companyAccessCode,
            settings: newSettings,
            key: {
                id: settingId,
            },
        };

        setOpenDeactivateModal(false);
        setDeactivating(true);
        try {
            dispatch({ type: actionConstants.START_LOADER });

            await updateIntegrationSettings(payload).unwrap();

            showSuccessToast(toasterMessage.TOKEN_DEACTIVATION_SUCCESS);
            setStartSetup(false);
            const lodgifyIndex = activeIntegrations?.findIndex((item) => item.name === 'Lodgify');

            const integrationsCopy = [...activeIntegrations];

            integrationsCopy[lodgifyIndex] = {
                ...integrationsCopy[lodgifyIndex],
                active: 0,
            };

            dispatch(setActiveIntegrations(integrationsCopy));
        } catch (err) {
            showErrorToast(err, toasterMessage.TOKEN_DEACTIVATION_FAILED);
        } finally {
            dispatch({ type: actionConstants.STOP_LOADER });
            setDeactivating(false);
        }
    };

    const onClickViewAll = () => {
        setOpenViewModal(true);
    };

    return (
        <Dashboard mainContainerStyles={classes.guestyActivePageWrapper}>
            <>
                <IntegrationHeader
                    title="Lodgify Integrations"
                    link="https://info.authenticate.com/lodgify-integration"
                />
                {isActive || startSetup ? (
                    <HomePageLayout
                        isProcessing={isProcessing && !deactivating}
                        onClickDeactivate={onClickDeactivate}
                        onClickViewAll={onClickViewAll}
                        brandUuid={defaultBrandUuid}
                    />
                ) : (
                    <ActivateGuesty
                        isProcessing={isProcessing}
                        onClickSetupNow={onClickSetupNow}
                        onClickActivateNow={onClickActivateNow}
                        integrationLink={redirectingUrls.LODGIFY_AUTHENTICATE_MARKETPLACE}
                    />
                )}

                <ActivateModal
                    open={openActivateModal}
                    setOpen={setOpenActivateModal}
                    onClose={onCloseActivateModal}
                    onSubmit={onActivateIntegration}
                />
                <DeactivateModal
                    open={openDeactivateModal}
                    setOpen={setOpenDeactivateModal}
                    onClose={onCloseDeactivateModal}
                    onConfirm={onDeactivateIntegration}
                />
                <ViewListings
                    brandUuid={defaultBrandUuid}
                    open={openViewModal}
                    onClose={() => setOpenViewModal(false)}
                />
            </>
        </Dashboard>
    );
};

export default LodgifyPage;
