import { PASSWORD_POLICY } from 'helper/constant';
import * as yup from 'yup';

// ================Login Form==============
export const loginFormInitialValues = {
    email: '',
    password: '',
};

export const loginFormValidationSchema = yup.object().shape({
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

// ===========ReNew Password Form============
export const reNewPasswordFormInitialValues = {
    password: '',
    confirmPassword: '',
};

export const reNewPasswordFormValidationSchema = yup.object().shape({
    password: yup
        .string()
        .matches(PASSWORD_POLICY.REGEX, PASSWORD_POLICY.ERROR_MESSAGE)
        .required('Password is required'),
    confirmPassword: yup
        .string('Enter your password')
        .required('Confirm Password is required')
        .oneOf([yup.ref('password')], 'The password and confirmation password do not match'),
});

// ===============Reset Password Form=============

export const resetPasswordFormInitialValues = {
    email: '',
};

export const resetPasswordFormValidationSchema = yup.object().shape({
    email: yup.string().email('Enter a valid email').required('Email is required'),
});

// ==============Two FA - Enter OTP Form==============

export const twoFAformInitialValues = {
    code: '',
};

export const twoFAformValidationSchema = yup.object().shape({
    code: yup
        .string()
        .length(6, 'One-time passcode must be exactly 6 characters')
        .required('One-time passcode is required'),
});
