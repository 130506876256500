import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import actionConstants from 'redux/actions/actionConstant';
import { openToast } from 'redux/actions/toaster';
import { useCreateBrandMutation, useUpdateBrandMutation } from 'redux/slices/globalApi';

import { getGuestyPassCriteria } from '../constants';

const useBrandNameForm = (defaultWorkflow, brandData, setBrandNameModal, isNewBrand) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { integrationName } = useParams();

    const [triggerUpdateBrandApi] = useUpdateBrandMutation();
    const [createNewBrand] = useCreateBrandMutation();

    const handleSuccess = (message, redirectUrl) => {
        dispatch(openToast({ variant: 'success', message }));
        if (redirectUrl) {
            history.push(redirectUrl);
        }
    };

    const submitNewBrand = async (values) => {
        const defaultPassCriteria = getGuestyPassCriteria(defaultWorkflow).map(
            (item) => item.value
        );

        const data = {
            payload: {
                brandName: values.brandName,
                passCriteria: defaultPassCriteria,
                preferredWorkflowId: defaultWorkflow?.nanoid,
            },
            integrationName,
        };

        const result = await createNewBrand(data).unwrap();
        if (result?.success) {
            handleSuccess(
                'Brand created successfully.',
                `/integrations/${integrationName}/brands/${result.uuid}`
            );
        }
    };

    const submitUpdateBrand = async (values) => {
        const workflowID = brandData?.preferredWorkflowConfig?.workflow?.nanoid;
        const query = {
            brandUUID: brandData?.uuid,
            payload: {
                brandName: values.brandName,
                passCriteria: brandData?.passCriteria,
                preferredWorkflowId: workflowID,
            },
            integrationName,
        };

        const result = await triggerUpdateBrandApi(query).unwrap();
        if (result?.success) {
            handleSuccess('Brand name is edited successfully.');
        }
    };

    const handleSubmit = async (values) => {
        try {
            dispatch({ type: actionConstants.START_LOADER });
            if (isNewBrand) {
                await submitNewBrand(values);
            } else {
                await submitUpdateBrand(values);
            }
        } catch (err) {
        } finally {
            dispatch({ type: actionConstants.STOP_LOADER });
            setBrandNameModal(false);
            brandNameForm.resetForm();
        }
    };

    const brandNameForm = useFormik({
        initialValues: {
            brandName: isNewBrand ? '' : brandData?.brandName,
        },
        validationSchema: yup.object().shape({
            brandName: yup.string().required('Brand name is required.').nullable(),
        }),
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    return { brandNameForm };
};

export default useBrandNameForm;
