import React from 'react';

import { Typography } from '@material-ui/core';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from '@xyflow/react';
import PropTypes from 'prop-types';

const CustomBaseEdge = ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    label,
    labelClass,
}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    }}
                >
                    <Typography className={labelClass}>{label}</Typography>
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

CustomBaseEdge.propTypes = {
    sourceX: PropTypes.number,
    sourceY: PropTypes.number,
    targetX: PropTypes.number,
    targetY: PropTypes.number,
    sourcePosition: PropTypes.string,
    targetPosition: PropTypes.string,
    style: PropTypes.object,
    markerEnd: PropTypes.string,
    label: PropTypes.string,
    labelClass: PropTypes.string,
};

export default CustomBaseEdge;
