import React from 'react';

import InputField from 'components/Input';

import { useTextFieldStyles } from './styles';

function Input(props) {
    const classes = useTextFieldStyles();
    return (
        <InputField
            {...props}
            labelClass={classes.inputFieldLabel}
            className={classes.inputFieldStyles}
        />
    );
}

export default Input;
