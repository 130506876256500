import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import Tooltip from 'components/Tooltip';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import styles from './editBrandHeader.module.css';

import { ReactComponent as BackIcon } from 'asset/IconsPack/at_arrow-narrow-left-filled.svg';
import { ReactComponent as SaveIcon } from 'asset/IconsPack/at_save-02.svg';

import MoreOption from '../MoreOption';

function EditBrandHeader({
    title,
    history,
    isDefaultBrand,
    handleDeleteBrand,
    defaultBrandScreen,
    unsavedChanges,
    setUnsavedChanges,
    canUserUpdate,
}) {
    const { integrationName } = useParams();
    const handleBackBtn = () => {
        if (unsavedChanges) {
            setUnsavedChanges(true);
        } else {
            history.push(`/integrations/${integrationName}`);
        }
    };

    const handleClose = () => {
        unsavedChanges && setUnsavedChanges(true);
    };

    return (
        <Grid>
            <Box className={styles.editBrandScreenHeader}>
                <Box className={styles.header}>
                    <IconButton className={styles.backIconBox} onClick={handleBackBtn}>
                        <BackIcon className={styles.backIcon} />
                    </IconButton>
                    <Typography className={styles.title}>{title}</Typography>
                </Box>
                {canUserUpdate && (
                    <Box className={styles.header}>
                        {!defaultBrandScreen && (
                            <Tooltip
                                title={isDefaultBrand ? 'You cannot delete the default brand.' : ''}
                                placement="bottom-end"
                                arrow
                            >
                                <span>
                                    <MoreOption
                                        disabled={isDefaultBrand}
                                        handleDeleteBrand={handleDeleteBrand}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        <Button
                            intent="secondary"
                            size="lg"
                            onClick={handleClose}
                            disabled={!unsavedChanges}
                        >
                            Cancel
                        </Button>
                        <Button
                            intent="primary"
                            startIcon={<SaveIcon className={styles.saveIcon} />}
                            size="lg"
                            type="submit"
                            disabled={!unsavedChanges}
                        >
                            Save
                        </Button>
                    </Box>
                )}
            </Box>
        </Grid>
    );
}

EditBrandHeader.propTypes = {
    title: PropTypes.string,
    history: PropTypes.object,
    isDefaultBrand: PropTypes.bool,
    handleDeleteBrand: PropTypes.func,
    defaultBrandScreen: PropTypes.bool,
    unsavedChanges: PropTypes.bool,
    setUnsavedChanges: PropTypes.func,
    canUserUpdate: PropTypes.bool,
};

export default EditBrandHeader;
