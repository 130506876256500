import Guesty from 'asset/icons/guesty_logo.svg';
import Hostaway from 'asset/icons/hostaway.svg';
import Lodgify from 'asset/icons/lodgify-logo.svg';
import MaskEcommerce from 'asset/icons/mask-ecommerce.svg';
import MaskGuesty from 'asset/icons/mask-guesty.svg';
import MaskHostaway from 'asset/icons/mask-hostaway.svg';
import MaskLodgify from 'asset/icons/mask-lodgify.svg';
import E_Commerce from 'asset/icons/shopify-2 1.svg';

export const integrationItems = [
    {
        key: 'guesty',
        name: 'Guesty',
        logo: Guesty,
        alt: 'Guestly Logo',
        description:
            'The ultimate property management platform for short-term and vacation rentals.',
        maskedLogo: MaskGuesty,
        isActive: false,
    },
    {
        key: 'eCommerce',
        name: 'E-Commerce',
        logo: E_Commerce,
        alt: 'E Commerce Logo',
        description:
            'Use our identity verification on platforms such as Shopify, Magento, Amazon, WooCommerce and many more!',
        maskedLogo: MaskEcommerce,
        isActive: false,
    },
    {
        key: 'hostaway',
        name: 'Hostaway',
        logo: Hostaway,
        alt: 'Hostaway Logo',
        description:
            ' Manage unlimited vacation rental channel reservations from check-in to check-out by one platform.',
        maskedLogo: MaskHostaway,
        isActive: false,
    },
    {
        key: 'lodgify',
        name: 'Lodgify',
        logo: Lodgify,
        alt: 'Lodgify Logo',
        description:
            'From website to guest management, Lodgify equips property owners with the tools they need to succeed in the rental industry.',
        maskedLogo: MaskLodgify,
        isActive: false,
    },
];

export const getGuestyPassCriteria = (workflow) => {
    const idVerification = workflow?.id_verifications;

    const govtId = idVerification?.find((item) => item.verification_type === 'govt_id');
    const isGovtEnabled = govtId?.enabled;
    const isSelfieEnabled = govtId?.metadata?.livenessCheck?.enabled ?? false;

    const isKbaEnabled = idVerification?.find((item) => item.verification_type === 'kba')?.enabled;
    const isBankEnabled = idVerification?.find(
        (item) => item.verification_type === 'bank'
    )?.enabled;
    const isSevenCheckEnabled = workflow?.additional_checks?.find(
        (item) => item.check_type === 'seven_year_criminal_report'
    )?.enabled;

    const isAuthDbCheckEnabled = workflow?.additional_checks?.find(
        (item) => item.check_type === 'authoritative_db'
    )?.enabled;

    return [
        {
            value: 'valid_and_verified',
            label: 'ID: Valid & Verified',
            disabled: !(isGovtEnabled && isAuthDbCheckEnabled),
        },
        {
            value: 'valid_and_not_verified',
            label: 'ID: Valid & Not Verified',
            disabled: !isGovtEnabled,
        },
        {
            value: 'valid_and_live_match_fail',
            label: 'ID: Valid & Live Match Fail',
            disabled: !(isGovtEnabled && isSelfieEnabled),
        },
        { value: 'valid_and_not_us_id', label: 'ID: Valid & Not US ID', disabled: !isGovtEnabled },
        { value: 'kba_verified', label: 'KBA: Verified', disabled: !isKbaEnabled },
        {
            value: 'fao_verified',
            label: 'Financial Account Ownership: Verified',
            disabled: !isBankEnabled,
        },
        {
            value: 'clear',
            label: '7 year criminal Activity check: Clear',
            disabled: !isSevenCheckEnabled,
        },
        {
            value: 'requires_review',
            label: '7 year criminal Activity check: Requires Review',
            disabled: !isSevenCheckEnabled,
        },
        {
            value: 'insufficient_data',
            label: '7 year criminal Activity check: Insufficient Data',
            disabled: !isSevenCheckEnabled,
        },
    ];
};

export const passCriteriaOptionsInfo = {
    valid_and_verified:
        "The Guest's ID has to be valid, and verifiable via authoritative databases.",
    valid_and_not_verified:
        "The Guest's ID has to be valid, but it doesn't have to be verified via authoritative databases.",
    valid_and_live_match_fail:
        "Guest's selfie doesn't match the photo on the ID, but the ID has to be valid.",
    valid_and_not_us_id: 'The Guest has to have a valid International ID card.',
    kba_verified: "The Guest's Identity has to pass KBA.",
    fao_verified: 'The Guest has to prove ownership of a financial account.',
    clear: "The Guest's 7 Year Criminal Report has to have returned no records",
    requires_review: "The Guest's 7 Year Criminal Report has to contain some records.",
    insufficient_data:
        "Insufficient data will prevent the generation of the Guest's 7 Year Criminal Report.",
};

export const GUESTY_SETTING_NAMES = {
    API: 'API Key',
    API_V2: 'ApiKeyV2',
    ACTIVE: 'Active',
    PASS_CRITERIA: 'passCriteria',
    ENABLED_ADDITIONAL_CHECKS: 'enabledAdditionalChecks',
};

export const HOSTAWAY_SETTING_NAMES = {
    ACCOUNT_ID: 'AccountId',
    API_KEY: 'ApiKey',
    ACTIVE: 'Active',
};

export const LODGIFY_SETTING_NAMES = {
    API_KEY: 'API Key',
    ACTIVE: 'Active',
};

export const ENABLED_ADDITIONAL_CHECKS = {
    VALUES: { sevenYearCheck: 'sevenYearCriminalStatus', verifyId: 'idStatus' },
};

export const redirectingUrls = {
    GUESTY_AUTHENTICATE_MARKETPLACE: 'https://www.guesty.com/marketplace-items/authenticating-com/',
    HOSTAWAY_AUTHENTICATE_MARKETPLACE: 'https://www.hostaway.com/marketplace/authenticating-com/',
    LODGIFY_AUTHENTICATE_MARKETPLACE: '', //TODO
};

export const toasterMessage = {
    TOKEN_ACTIVATION_SUCCESS: 'Token activation was successful.',
    TOKEN_ACTIVATION_FAILED: 'Token activation failed, please try again.',
    TOKEN_DEACTIVATION_SUCCESS: 'You have deactivated this integration.',
    TOKEN_DEACTIVATION_FAILED: 'Token deactivation failed, please try again.',
};

export const VERIFICATIONS_VALUES = {
    GOVT_ID: 'govt_id',
    KBA: 'kba',
    FAO: 'bank',
    AUTH_DB: 'authoritative_db',
    CRIMINAL_CHECK: 'has_criminal_record',
    SEVEN_YEAR_CRIMINAL_CHECK: 'seven_year_criminal_report',
    EDUCATION: 'education',
    EMPLOYMENT: 'employment',
    PROFESSIONAL_LICENSE: 'professional_license',
};

export const VERIFICATION_LABEL = {
    [VERIFICATIONS_VALUES.GOVT_ID]: 'Government ID',
    [VERIFICATIONS_VALUES.KBA]: 'KBA',
    [VERIFICATIONS_VALUES.FAO]: 'Financial Account Ownership',
    [VERIFICATIONS_VALUES.AUTH_DB]: 'Authoritative DB Check',
    [VERIFICATIONS_VALUES.CRIMINAL_CHECK]: 'Criminal Check',
    [VERIFICATIONS_VALUES.SEVEN_YEAR_CRIMINAL_CHECK]: '7 Year Criminal Check',
    [VERIFICATIONS_VALUES.EDUCATION]: 'Education',
    [VERIFICATIONS_VALUES.EMPLOYMENT]: 'Employment',
    [VERIFICATIONS_VALUES.PROFESSIONAL_LICENSE]: 'Professional License',
};

export const passCriteria = [
    'valid_and_verified',
    'valid_and_not_verified',
    'valid_and_live_match_fail',
    'valid_and_not_us_id',
    'kba_verified',
    'fao_verified',
    'clear',
    'requires_review',
    'insufficient_data',
];
export const GROUP_NODE_WIDTH = 342;

export const rootNode = {
    id: 'root',
    position: {
        x: 0,
        y: 0,
    },
    type: 'root',
    data: {
        label: 'Verification Passed if',
    },
    measured: {
        width: GROUP_NODE_WIDTH,
        height: 141,
    },
};

export const parentNodeMapper = {
    document_root: {
        label: 'ID Document is',
        type: 'document',
        children: [
            'valid_and_verified',
            'valid_and_not_verified',
            'valid_and_live_match_fail',
            'valid_and_not_us_id',
        ],
        measured: {
            width: 278,
        },
    },
    kba_root: {
        label: 'KBA is',
        type: 'kba',
        children: ['kba_verified'],
        measured: {
            width: 278,
        },
    },
    fao_root: {
        label: 'Financial Account Ownership is',
        type: 'fao',
        children: ['fao_verified'],
        measured: {
            width: 430,
        },
    },
    criminal_root: {
        label: '7-year Criminal Activity Check',
        type: 'criminal',
        children: ['clear', 'requires_review', 'insufficient_data'],
        measured: {
            width: 418,
        },
    },
};

export const childNodeMapper = {
    valid_and_verified: {
        data: {
            label: 'Valid and Verified',
            type: 'verified',
        },
        measured: {
            width: 244,
        },
    },
    valid_and_not_verified: {
        data: {
            label: 'Valid and Not Verified',
            type: 'verified',
        },
        measured: {
            width: 275,
        },
    },
    valid_and_live_match_fail: {
        data: {
            label: 'Valid and Live Match Fail',
            type: 'verified',
        },
        measured: {
            width: 298,
        },
    },
    valid_and_not_us_id: {
        data: {
            label: 'Valid and Not US',
            type: 'verified',
        },
        measured: {
            width: 238,
        },
    },
    kba_verified: {
        data: {
            label: 'Verified',
            type: 'verified',
        },
        measured: {
            width: 171,
        },
    },
    fao_verified: {
        data: {
            label: 'Verified',
            type: 'verified',
        },
        measured: {
            width: 171,
        },
    },
    clear: {
        data: {
            label: 'Clear',
            type: 'verified',
        },
        measured: {
            width: 151,
        },
    },
    requires_review: {
        data: {
            label: 'Requires Attention',
            type: 'warning',
        },
        measured: {
            width: 255,
        },
    },
    insufficient_data: {
        data: {
            label: 'Insufficient Data',
            type: 'noData',
        },
        measured: {
            width: 239,
        },
    },
};

export const PROPERTY_RENTALS = {
    GUESTY: 'guesty',
    HOSTAWAY: 'hostaway',
    LODGIFY: 'lodgify',
};
