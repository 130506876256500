import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';
import { convertToFirstCapital } from 'helper/utility';
import PropTypes from 'prop-types';

import classes from './activateGuesty.module.css';

import { ReactComponent as LockIcon } from 'asset/IconsPack/at_lock-04-filled.svg';
import IntegrationLoader from 'asset/img/integration_loading.gif';

const ActivateGuesty = ({ isProcessing, onClickSetupNow, onClickActivateNow, integrationLink }) => {
    const { integrationName } = useParams();
    return (
        <div>
            <Box className={classes.main}>
                <Box className={classes.container}>
                    {isProcessing ? (
                        <>
                            <img src={IntegrationLoader} alt="loading-animation" />
                            <Typography className={classes.description}>
                                Your activation is in process. In the meantime you can setup your
                                Integration
                            </Typography>
                            <Button
                                intent="primary"
                                onClick={onClickSetupNow}
                                className={classes.primaryBtn}
                            >
                                Setup Now
                            </Button>
                        </>
                    ) : (
                        <>
                            <LockIcon className={classes.lockIcon} />
                            <Typography className={classes.description}>
                                You need to activate to view the Integration details
                            </Typography>
                            <Button
                                intent="primary"
                                onClick={onClickActivateNow}
                                className={classes.primaryBtn}
                            >
                                Activate Now
                            </Button>
                            <a
                                href={integrationLink}
                                target="_blank"
                                className={classes.guestyLink}
                                rel="noreferrer"
                            >
                                View in {convertToFirstCapital(integrationName)} Marketplace
                            </a>
                        </>
                    )}
                </Box>
            </Box>
        </div>
    );
};

ActivateGuesty.propTypes = {
    isProcessing: PropTypes.bool,
    onClickSetupNow: PropTypes.func,
    onClickActivateNow: PropTypes.func,
    integrationLink: PropTypes.string,
};

export default ActivateGuesty;
